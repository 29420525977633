import styled from 'styled-components';

import { customMedia, media } from './../common/MediaQueries';
import { transition } from './../common/mixins';

export const SliderWrapperStyled = styled.div`
  width: 100%;

  .swiper-container {
    @media ${media.phone} {
      padding: 0 2rem;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    ${transition};
    width: 10px;
    height: 22px;
    background-size: 10px;
    margin-top: 0;
    outline: 0;

    &:hover {
      opacity: 0.6;
    }
  }
  .swiper-button-prev {
    left: 0;
    transform: rotate(180deg);
  }
  .swiper-button-next {
    right: 0;
  }
  .swiper-button-disabled {
    opacity: 0;
  }
  .swiper-pagination {
    position: static;
    margin-top: 40px;
  }
  .swiper-pagination-bullet {
    opacity: 0.1;
  }
  .swiper-pagination-bullet-active {
    opacity: 1;
  }

  .slick-dots {
    position: static;
    margin-top: 40px;
  }

  .slick-dots li {
    width: auto;
    height: auto;
  }

  .slick-dots li button {
    width: 8px;
    height: 8px;
    border-radius: 100%;
  }

  .slick-dots li button {
    opacity: 0.2;
  }

  .slick-dots li.slick-active button {
    opacity: 1;
  }

  .slick-arrow {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 10px;
    height: 22px;
    &:before {
      content: '';
      background-position: 50% 0%;
      background-repeat: no-repeat;
      width: 10px;
      height: 22px;
      background-size: cover;
    }
  }

  .slick-next {
    right: 0;
  }
  .slick-prev {
    left: 0;
    &::before {
      transform: rotate(180deg);
    }
  }

  @media ${customMedia.maxPhone} {
    .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
      margin: 0 12px;
    }
    .slick-dots li {
      margin: 0 12px;
    }
  }
`;

export default SliderWrapperStyled;
