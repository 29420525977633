import React from "react";

import Container from "./../../components/Container";
import Section from "./../../components/Section";
import Inner from "./../../components/Inner";

import Testimonials from "./../../components/Testimonials";
import TESTIMONIALS_SLIDER_CFG from "./../../constants/sliders/testimonialsCfg";
import { ITestimonial } from "../../interfaces/ITestimonial";

interface Props { 
  block: {
    testimonials: ITestimonial[]
  }
}

const FlexGetHiredBgBlock = ({ block }: Props) => {
  return (
    <Container pb={["", "67px"]}>
      <Inner noOffsetMobile>
        <Container backgroundImage="linear-gradient(180deg, #FF7C9C 0%, #FF5070 100%);">
          <Container pt={["40px", "50px"]} pb={["40px", "50px"]}>
            <Section>
              <Inner isSmall>
                <Testimonials
                  isAlt
                  items={block.testimonials}
                  settings={TESTIMONIALS_SLIDER_CFG}
                />
              </Inner>
            </Section>
          </Container>
        </Container>
      </Inner>
    </Container>
  );
};

export default FlexGetHiredBgBlock;
