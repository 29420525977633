import React from "react";

import {
  GetStartedCtaStyled,
  GetStartedCtaMediaStyled,
  GetStartedCtaContentStyled,
  GetStartedCtaContentItemStyled,
  GetStartedCtaContentWrapperStyled,
} from "./styles";
import Image from "./../Image";
import { Typo1, Typo7 } from "./../Typography";
import theme from "../../themes/theme";

interface IProps {
  heading: string;
  desc: string;
  image?: any;
  alt: string;
  button?: React.ReactNode;
}

const GetStartedCta: React.FC<IProps> = ({
  heading,
  desc,
  button,
  image,
  alt,
}) => (
  <GetStartedCtaStyled>
    <GetStartedCtaMediaStyled>
      {image && <Image fluid={image} alt={`${alt} photo`} />}
    </GetStartedCtaMediaStyled>
    <GetStartedCtaContentWrapperStyled>
      <GetStartedCtaContentStyled>
        <GetStartedCtaContentItemStyled>
          <Typo1 fontWeight={theme.fontWeight.light}>{heading}</Typo1>
        </GetStartedCtaContentItemStyled>
        <GetStartedCtaContentItemStyled>
          <Typo7 fontWeight={theme.fontWeight.bold}>{desc}</Typo7>
        </GetStartedCtaContentItemStyled>
        {button && (
          <GetStartedCtaContentItemStyled>
            {button}
          </GetStartedCtaContentItemStyled>
        )}
      </GetStartedCtaContentStyled>
    </GetStartedCtaContentWrapperStyled>
  </GetStartedCtaStyled>
);

export default GetStartedCta;
