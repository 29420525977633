import React from "react";

import Container from "./../../components/Container";
import Section from "./../../components/Section";
import Inner from "./../../components/Inner";

import HireFeatures from "./../../components/HireFeatures";
import { IFeature } from "../../interfaces/IFeature";

interface Props { 
  block: { 
    heading: string 
    description: string
    numbersWithDescription: IFeature[]
    text?: string
  }
  divSubheading?: boolean
}

const FlexGetHiredFeatures = ({ block, divSubheading }: Props) => {
  return (
    <Container pt={["54px", "105px"]}>
      <Inner isSmall>
        <Section id="section-get-hired">
          <HireFeatures
            getHired
            heading={block.heading}
            desc={block.description}
            text={block.text}
            items={block.numbersWithDescription}
            divSubheading={divSubheading}
          />
        </Section>
      </Inner>
    </Container>
  );
};

export default FlexGetHiredFeatures;
