import React from "react";
import Slider from "react-slick";

import "./../Slick/vendor/slick-theme.scss";
import "./../Slick/vendor/slick.scss";
import { TestimonialsStyled, TestimonialsWrapperStyled } from "./styles";
import SliderWrapperStyled from "./../SliderWrapper";
import TestimonialItem from "./../TestimonialItem";
import Inner from "./../Inner";

import { ITestimonial } from "./../../interfaces/ITestimonial";

interface IProps {
  items: ITestimonial[];
  settings: object;
  isAlt?: boolean;
}

const Testimonials: React.FC<IProps> = ({ items, settings, isAlt }) => {
  return (
    <Inner isSmall noOffsetMobile>
      <TestimonialsWrapperStyled {...{ isAlt }}>
        <TestimonialsStyled>
          <SliderWrapperStyled>
            <Slider {...settings}>
              {items.map(({ fullName, description, testimonial, image }) => (
                <TestimonialItem
                  {...{ fullName, description, image }}
                  key={fullName}
                >
                  {testimonial}
                </TestimonialItem>
              ))}
            </Slider>
          </SliderWrapperStyled>
        </TestimonialsStyled>
      </TestimonialsWrapperStyled>
    </Inner>
  );
};

export default Testimonials;
