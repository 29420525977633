import styled from 'styled-components';
import { rgba } from 'polished';

import { customMedia, media } from './../../common/MediaQueries';

const TestimonialItemContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const TestimonialItemStyled = styled.div`
  display: flex;
  flex-direction: column;

  color: ${({ theme }) => theme.colors.white};

  padding: 0 16px;

  @media ${media.phone} {
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    padding: 0 32px;
    width: 100%;
    flex-direction: row;
    max-width: 700px;
  }
`;

const TestimonialItemImgWrapperStyled = styled.div`
  position: relative;
  display: flex;
  border-radius: 100%;

  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => rgba(theme.colors.white, 0.3)};
  width: 125px;
  height: 125px;
  margin: 0 auto 12px;

  @media ${media.phone} {
    width: 190px;
    height: 190px;
    margin: 0 2rem 0 0;
  }
`;

const TestimonialItemImgStyled = styled.div`
  width: 90px;
  height: 90px;
  border-radius: 100%;
  overflow: hidden;
  img {
    overflow: hidden;
    border-radius: 100%;
  }
  @media ${customMedia.maxPhone} {
    img {
      opacity: 1 !important;
    }
  }
  @media ${media.phone} {
    width: 160px;
    height: 160px;
  }
`;

const TestimonialItemContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  @media ${media.phone} {
    text-align: left;
  }
`;

const TestimonialItemQuoteStyled = styled.blockquote`
  font-weight: ${({ theme }) => theme.fontWeight.light};
  margin-bottom: 1rem;
`;

const TestimonialItemNameStyled = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

const TestimonialItemTitleStyled = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.light};
`;

export {
  TestimonialItemContainerStyled,
  TestimonialItemStyled,
  TestimonialItemImgWrapperStyled,
  TestimonialItemImgStyled,
  TestimonialItemNameStyled,
  TestimonialItemQuoteStyled,
  TestimonialItemTitleStyled,
  TestimonialItemContentStyled,
};
