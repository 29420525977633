import React from "react";

import {
  HireFeaturesWrapper,
  HireFeaturesHeadingStyled,
  HireFeaturesDescStyled,
  HireFeaturesHeadingWrapper,
  HireFeaturesHeading,
  HireFeaturesHeadingText,
} from "./styles";
import { Flex, FlexItem } from "./../Flex";
import Container from "./../Container";
import Infobox from "./../Infobox";
import { IFeature } from "../../interfaces/IFeature";
import { Typo4 } from "./../Typography";
import theme from "./../../themes/theme";

export interface IFeatureItem {
  items: IFeature[];
  heading: string
  desc?: string
  text?: string
  hired?: boolean;
  getHired?: boolean;
  divSubheading?: boolean
}

const HireFeatures: React.FC<IFeatureItem> = ({
  heading,
  desc,
  text, 
  items,
  hired,
  getHired,
  divSubheading,
}) => {

  return (
    <HireFeaturesWrapper {...{ hired, getHired }}>
      <HireFeaturesHeadingWrapper>
        <HireFeaturesHeading>
          <HireFeaturesHeadingStyled
            as={getHired ? 'h1' : undefined}
            dangerouslySetInnerHTML={{ __html: heading }}
          />
          {desc && (
            <HireFeaturesDescStyled>
              <Typo4
                as={divSubheading ? 'div' : undefined}
                dangerouslySetInnerHTML={{ __html: desc }}
              />
            </HireFeaturesDescStyled>
          )}
        </HireFeaturesHeading>
        {text &&
          <HireFeaturesHeadingText>
            <Typo4
              as="div"
              dangerouslySetInnerHTML={{ __html: text }}
            />
          </HireFeaturesHeadingText>
        }
      </HireFeaturesHeadingWrapper>
      <Flex flexWrap={["wrap", ""]}>
        {items.map(({ id, heading, description, number, icon }) => (
          <FlexItem
            flex={["1 0 50%", "1 0 33%"]}
            pb={["60px", "90px"]}
            key={id}
          >
            <Container width={["100%", "100%"]} pr={["21px", "60px", "100px"]}>
              <Infobox iconSize="52px" {...{ heading, number, icon }}>
                <Typo4
                  as="div"
                  fontWeight={theme.fontWeight.light}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </Infobox>
            </Container>
          </FlexItem>
        ))}
      </Flex>
    </HireFeaturesWrapper>
  );
};

export default HireFeatures;
