import styled, { css } from 'styled-components';

import { media, customMedia } from './../../common/MediaQueries';
import {
  InfoBoxStyled,
  InfoBoxIconWrapperStyled,
  InfoBoxDescStyled,
  InfoBoxNumberWrapperStyled,
} from './../Infobox/styles';
import { FlexItem } from './../Flex';
import Container from './../Container';

const HireFeaturesHeadingStyled = styled.h2`
  font-family: ${({ theme }) => theme.fontFamilies.proximaNova};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 25px;
  margin-bottom: 16px;

  mark,
  i {
    color: ${({ theme }) => theme.colors.white};
    display: table;
    padding: 0 8px;
    font-size: 40px;
    line-height: 1;
    margin: 0 0 4px 0;
    @media ${media.phone} {
      font-size: 50px;
      padding: 0 18px;
    }
  }

  @media ${media.phone} {
    font-size: 30px;
    margin-bottom: 18px;
  }
`;

const HireFeaturesDescStyled = styled.div`
  margin-bottom: 20px;

  @media ${media.tablet} {
    margin-bottom: 0;
  }
`;

const HireFeaturesHeadingWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 56px;

  @media ${media.phone} {
    margin-bottom: 82px;
  }
`;

export const HireFeaturesHeading = styled.div`
  width: 100%;

  @media ${media.tablet} {
    width: 50%;
    padding-right: 40px;
  }
`;

export const HireFeaturesHeadingText = styled.div`
  width: 100%;
  border-top: 1px solid #dedede;
  padding-top: 20px;

  @media ${media.tablet} {
    width: 50%;
    padding-top: 10px;
    padding-bottom: 10px;
    border-left: 1px solid #dedede;
    padding-left: 40px;
    text-align: justify;
    border-top: none;
    border-left: 1px solid #dedede;
  }

  & p {
    margin: 10px 0;
  }

  & p:first-child {
    margin-top: 0;
  }

  & p:last-child {
    margin-bottom: 0;
  }
`;

interface HireFeaturesWrapperProps {
  hired?: boolean;
  getHired?: boolean;
}

const HireFeaturesWrapper = styled.div<HireFeaturesWrapperProps>`
  ${FlexItem} {
    @media ${customMedia.maxPhone} {
      ${InfoBoxStyled} {
        padding: 0;
      }

      ${InfoBoxIconWrapperStyled} {
        margin: 0 0 28px;
        img {
          margin: 0;
        }
      }

      ${InfoBoxDescStyled} {
        text-align: left;
      }
    }

    @media ${media.phone} {
      &::nth-child(3n) {
        ${Container} {
          padding-right: 0;
        }
      }
    }
  }

  ${({ hired }) =>
    hired &&
    css`
      ${HireFeaturesHeadingStyled} {
        mark,
        i {
          background-image: ${({ theme }) => theme.gradients.main};
        }
      }
      ${InfoBoxNumberWrapperStyled} {
        color: ${({ theme }) => theme.colors.main};
        &::before {
          background-color: ${({ theme }) => theme.colors.main};
        }
      }
    `}

  ${({ getHired }) =>
    getHired &&
    css`
      ${HireFeaturesHeadingStyled} {
        mark,
        i {
          background-image: ${({ theme }) => theme.gradients.watermelon};
        }
      }

      ${InfoBoxNumberWrapperStyled} {
        color: ${({ theme }) => theme.colors.watermelon};
        &::before {
          background-color: ${({ theme }) => theme.colors.watermelon};
        }
      }
    `}
`;

export {
  HireFeaturesWrapper,
  HireFeaturesHeadingWrapper,
  HireFeaturesHeadingStyled,
  HireFeaturesDescStyled,
};
