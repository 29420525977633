import styled, { css } from 'styled-components';

import { media } from './../../common/MediaQueries';
import arrowIconBlue from './../../assets/images/svg/slider-arrow-blue-icon.svg';
import arrowIconWhite from './../../assets/images/svg/slider-arrow-alt-icon.svg';
import { SliderWrapperStyled } from './../SliderWrapper';
import { TestimonialItemNameStyled } from './../TestimonialItem/styles';

const TestimonialsWrapperStyled = styled.div`
  width: 100%;

  ${SliderWrapperStyled} {
    .swiper-container {
      padding: 0 1rem;
      @media ${media.phone} {
        padding: 0;
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      background-image: url(${arrowIconBlue});
      top: 32%;
      background-size: 1rem;
      width: 1rem;
      @media ${media.phone} {
        background-size: 2rem;
        width: 2rem;
      }
    }
    .swiper-pagination-bullet,
    .swiper-pagination-bullet-active {
      background-color: ${({ theme }) => theme.colors.white};
    }
    .swiper-container {
      .swiper-pagination {
        margin-top: 40px;
        @media ${media.phone} {
          margin-top: 36px;
        }
      }
    }

    .slick-arrow {
      width: 1rem;
      top: 32%;
      &:before {
        background-image: url(${arrowIconBlue});
        width: 1rem;
        height: 22px;
        background-size: contain;
        @media ${media.phone} {
          width: 2rem;
          height: 28px;
        }
      }
      @media ${media.phone} {
        width: 2rem;
      }
    }

    .slick-dots li button {
      background: ${({ theme }) => theme.colors.white};
    }

    .slicks-dots li.slick-active button {
      background: ${({ theme }) => theme.colors.white};
      opacity: 1;
    }

    .slick-prev,
    .slick-next {
      transform: none;
    }
    .slick-slider {
      padding: 0 1rem;
    }
  }

  ${({ isAlt }) =>
    isAlt &&
    css`
      ${SliderWrapperStyled} {
        .swiper-button-prev,
        .swiper-button-next {
          background-image: url(${arrowIconWhite});
        }
        .slick-arrow {
          &:before {
            background-image: url(${arrowIconWhite});
          }
        }
      }
      ${TestimonialItemNameStyled} {
        color: ${({ theme }) => theme.colors.white};
      }
    `}
`;

const TestimonialsStyled = styled.div`
  width: 100%;
`;

export { TestimonialsStyled, TestimonialsWrapperStyled };
