import React from "react";

import Container from "./../../components/Container";
import Section from "./../../components/Section";
import Inner from "./../../components/Inner";

import GetStartedCta from "./../../components/GetStartedCta";
import ButtonLink from "./../../components/ButtonLink";

interface Props { 
  block: { 
    heading: string 
    description: string
    button: { 
      target?: boolean
      url: string 
      title: string
    }
    image: {
      imageFile: any
      altText: string
    }
  }
}

const FlexGetHiredCta = ({ block }: Props) => {
  return (
    <Container pb={["36px", "4.5rem"]}>
      <Inner>
        <Section>
          <Inner isSmall noOffsetMobile>
            <GetStartedCta
              heading={block.description}
              desc={block.heading}
              button={
                block.button.target ? (
                  <ButtonLink
                    as="a"
                    $internal
                    $pink
                    href={block.button.url}
                    target="_blank"
                  >
                    {block.button.title}
                  </ButtonLink>
                ) : (
                  <ButtonLink $internal $pink to={block.button.url}>
                    {block.button.title}
                  </ButtonLink>
                )
              }
              image={[block.image.imageFile]}
              alt={block.image.altText}
            />
          </Inner>
        </Section>
      </Inner>
    </Container>
  );
};

export default FlexGetHiredCta;
