import React from "react";

import {
  TestimonialItemContainerStyled,
  TestimonialItemStyled,
  TestimonialItemImgWrapperStyled,
  TestimonialItemImgStyled,
  TestimonialItemNameStyled,
  TestimonialItemQuoteStyled,
  TestimonialItemTitleStyled,
  TestimonialItemContentStyled,
} from "./styles";
import { Typo8, Typo12 } from "../Typography";
import Image from "./../Image";

interface IProps {
  fullName: string;
  description?: string;
  image: object;
  testimonial: string;
}

const TestimonialItem: React.FC<IProps> = ({
  fullName,
  description,
  children,
  image,
}) => {
  return (
    <TestimonialItemContainerStyled>
      <TestimonialItemStyled>
        <TestimonialItemImgWrapperStyled>
          <TestimonialItemImgStyled>
            {image.src ? (
              <Image fluid={["", image]} alt={`${fullName} photo`} />
            ) : (
              <Image fluid={["", image.imageFile]} alt={`${fullName} photo`} />
            )}
          </TestimonialItemImgStyled>
        </TestimonialItemImgWrapperStyled>
        <TestimonialItemContentStyled>
          <TestimonialItemQuoteStyled>
            <Typo12 fontWeight="400">"{children}"</Typo12>
          </TestimonialItemQuoteStyled>
          <TestimonialItemNameStyled>
            <Typo8 fontWeight="700">{fullName}</Typo8>
          </TestimonialItemNameStyled>
          {description && (
            <TestimonialItemTitleStyled>
              <Typo8 fontWeight="400">{description}</Typo8>
            </TestimonialItemTitleStyled>
          )}
        </TestimonialItemContentStyled>
      </TestimonialItemStyled>
    </TestimonialItemContainerStyled>
  );
};

export default TestimonialItem;
