import styled from 'styled-components';

import { customMedia, media } from './../../common/MediaQueries';

const GetStartedCtaStyled = styled.div`
  display: flex;
  flex-direction: column;

  @media ${customMedia.maxPhone} {
    border-top: 1px solid ${({ theme }) => theme.colors.grey};
    padding: 40px 0;
  }

  @media ${media.phone} {
    flex-direction: row;
  }
`;

const GetStartedCtaMediaStyled = styled.div`
  width: 174px;
  @media ${customMedia.maxPhone} {
    .gatsby-image-wrapper {
      left: -1rem;
    }
  }
  @media ${media.phone} {
    width: 380px;
  }
`;

const GetStartedCtaContentStyled = styled.div`
  @media ${media.phone} {
    border-top: 1px solid ${({ theme }) => theme.colors.grey};
    margin-left: 80px;
    padding: 54px 8px 0;
  }
`;

const GetStartedCtaContentWrapperStyled = styled.div`
  @media ${media.phone} {
    width: calc(100% - 380px);
    padding-top: 46px;
    padding-rgiht: 40px;
  }
`;

const GetStartedCtaContentItemStyled = styled.div`
  margin-bottom: 20px;
  max-width: 500px;
`;

export {
  GetStartedCtaStyled,
  GetStartedCtaMediaStyled,
  GetStartedCtaContentStyled,
  GetStartedCtaContentItemStyled,
  GetStartedCtaContentWrapperStyled,
};
