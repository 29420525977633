import React from "react";

import Container from "./../../components/Container";
import Section from "./../../components/Section";
import Inner from "./../../components/Inner";
import { Flex, FlexItem } from "./../../components/Flex";
import theme from "./../../themes/theme";

import Banner from "./../../components/Banner";
import { Heading6, Typo6 } from "./../../components/Typography";

interface Props { 
  block: { 
    heading?: string 
    subheading?: string
    backgroundImageMobile: { 
      imageFile: any
      altText: string
    }
    backgroundImageDesktop: { 
      imageFile: any
      altText: string
    }
  }  
}

const FlexContentBannerAlt = ({ block }: Props) => {
  const imageDesktop =
    block.backgroundImageDesktop !== null
      ? block.backgroundImageDesktop.imageFile
      : "";

  const imageMobile =
    block.backgroundImageMobile !== null
      ? block.backgroundImageMobile.imageFile
      : block.backgroundImageDesktop !== null
      ? block.backgroundImageDesktop.imageFile
      : "";

  const imageAlt =
    block.backgroundImageDesktop !== null
      ? block.backgroundImageDesktop.altText
      : "";

  return (
    <Container>
      <Inner noOffsetMobile>
        <Section>
          <Banner
            // image={[
            //   imageMobile,
            //   {
            //     ...imageDesktop,
            //     media: `(min-width: 768px)`,
            //   },
            // ]}
            image={[imageMobile, imageDesktop]}
            altText={imageAlt}
            content={
              block.heading || block.subheading ? (
                <Flex flexDirection={["column", "", "row"]}>
                  {block.heading && (
                    <FlexItem
                      width={["100%", "", "60%"]}
                      pb={["28px", "", "0"]}
                    >
                      <Container width={"100%"} pr={["", "62px"]}>
                        <Heading6 as="h2">{block.heading}</Heading6>
                      </Container>
                    </FlexItem>
                  )}
                  {block.subheading && (
                    <FlexItem width={["100%", "", "40%"]}>
                      <Container width={"100%"} pr={["", "", "118px"]}>
                        <Typo6 fontWeight={theme.fontWeight.light}>
                          {block.subheading}
                        </Typo6>
                      </Container>
                    </FlexItem>
                  )}
                </Flex>
              ) : (
                ""
              )
            }
          />
        </Section>
      </Inner>
    </Container>
  );
};

export default FlexContentBannerAlt;
